.navbar-container {
  position: fixed;
  background-color: #f9f9f9;
  width: 100%;
  font-family: "BR Firma";
  z-index: 70;
}

.navbar-container--inner {
  max-width: 1240px;
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Left Section */
.navbar-left-section-wrapper {
}

.navbar-company-logo-wrapper img {
  height: 68px;
}

/* Right Section */
.navbar-right-section-wrapper {
  display: flex;
}

.navbar-link-wrapper {
  margin-left: 50px;
}

.navbar-link-wrapper a {
  text-decoration: none;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all;
  transition-duration: 300ms;
}

.be-a-partner-link-wrapper a {
  width: 159px;
  border: 1.5px solid #71bbe0;
  border-radius: 20px;
}

.be-a-partner-link-wrapper a:hover {
  background-color: #71bbe0;
}

.download-link-wrapper a {
  width: 159px;
  background-color: #71bbe0;
  border-radius: 20px;
}

.download-link-wrapper a:hover {
  color: #f9f9f9;
}

/* MOBILE SLIDE NAVBAR SECTION */
.mobile-navburger-btn-container {
  display: none;
}

.mobile-navburger-btn-container button {
  border: 1px solid rgba(38, 38, 38, 0.5);
  background-color: inherit;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

.mobile-navburger-btn-container button img {
  height: 25px;
}

.MOBILE__slide-nav-bar-section {
  position: absolute;
  /* background-color: rebeccapurple; */
  background-color: #f9f9f9;
  width: 100%;
  min-height: 100vh;
  z-index: 55;
  padding: 0 0 30px;
}

.MOBILE__slide-nav-bar-section--inner {
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.mobile-navclose-btn-container button {
  border: none;
  background-color: inherit;
  outline: none;
  cursor: pointer;
}

.mobile-navclose-btn-container button img {
  height: 30px;
}

.mobile-main-nav-wrapper {
  padding: 0 20px;
  margin-top: 20px;
}

.mobile-nav-link {
  border-bottom: 1px solid rgba(189, 189, 189, 0.4);
  padding: 10px;
  margin-bottom: 20px;
}

.mobile-nav-link a {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #222222;
}

.mobile-nav-link button {
  display: flex;
  align-items: center;
  border: none;
  background-color: inherit;
  outline: none;
  cursor: pointer;
  font-family: lato;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #222222;
  padding: 0;
}

/* Mobile Logout button Section */
.mobile-logout-btn-container {
  position: absolute;
  bottom: 5px;
  padding-left: 10px;
}

.mobile-logout-btn-container button {
  border: none;
  margin: 0 18px;
  background-color: inherit;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #222222;
}

.mobile-sign-up-sub-link {
  background-color: #ffffff;
  padding: 16px 12px;
  margin-left: 14px;
  margin-right: 24px;
  border-bottom: 1px solid rgba(189, 189, 189, 0.4);
}

.mobile-sign-up-sub-link a {
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #757575;
}

.mobile-sign-up-nav-link,
.mobile-sign-up-as-myself,
.mobile-sign-up-as-agent {
  margin-bottom: 0;
}

.mobile-sign-in-nav-link {
  padding-top: 20px;
}

/* Overflow hidden class */
.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

.none {
  display: none;
}

/* Media queries */
@media (max-width: 1290px) {
  .navbar-container--inner {
    padding: 0 20px;
  }
}

@media (max-width: 890px) {
  .navbar-link-wrapper {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .navbar-company-logo-wrapper img {
    height: 50px;
  }

  .navbar-right-section-wrapper {
    display: none;
  }

  .mobile-navburger-btn-container {
    display: block;
  }
}
