.be-a-partner-container {
  background-color: rgba(241, 241, 241, 0.7);
}
.be-a-partner-container--inner {
  position: relative;
  max-width: 1240px;
  margin: auto;
  padding: 50px 0;
  min-height: 100vh;
  font-family: "BR Firma";
  padding-top: 80px;
  overflow: hidden;
}

/* be-a-partner-form-wrapper */
.be-a-partner-form-wrapper {
  margin-top: 50px;
}

.form-title {
  font-size: 26px;
  font-weight: 500;
}

.be-a-partner-form-wrapper form {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 30px;
  column-gap: 30px;
  max-width: 900px;
  margin-top: 30px;
}

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: span 6 / span 6;
}

.form-group label {
  font-size: 14px;
  margin: 0px 0 5px;
  padding-left: 10px;
}

.form-group select,
.form-group input,
.form-group textarea {
  height: 40px;
  border-radius: 20px;
  background-color: inherit;
  border: 1px solid #71bbe0;
  padding: 0 20px;
  font-size: 13px;
}

.form-group input::placeholder {
  font-size: 13px;
}

.form-group select:focus,
.form-group input:focus,
.form-group textarea:focus {
  outline: 1px solid #71bbe0;
}

select::-ms-expand {
  display: none;
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.angle-down-icon {
  position: absolute;
  right: 25px;
  bottom: 10px;
  height: 20px;
  filter: invert(0.2);
}

.submit-form-button-wrapper {
  margin-top: 20px;
  grid-column: span 10 / span 10;
  display: flex;
  align-items: center;
}

.submit-form-button-wrapper button {
  width: 180px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #71bbe0;
  border-radius: 30px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;
  color: #f9f9f9;
  margin-right: 10px;
}

/* Media queries */
@media (max-width: 1290px) {
  /*be-a-partner-container*/
  .be-a-partner-container--inner {
    padding: 50px 20px;
  }
}

@media (max-width: 768px) {
  .submit-form-button-wrapper button {
    width: 160px;
    height: 45px;
  }

  .angle-down-icon {
    height: 18px;
    /* bottom: 52%; */
  }
}

@media (max-width: 530px) {
  .form-title {
    font-size: 22px;
    font-weight: 500;
  }

  .be-a-partner-form-wrapper form {
    column-gap: 0px;
  }

  .form-group {
    grid-column: span 10 / span 10;
  }

  .submit-form-button-wrapper {
    margin-top: 10px;
  }

  .submit-form-button-wrapper button {
    width: 140px;
    height: 40px;
    font-size: 14px;
  }

  .form-group label {
    font-size: 13px;
    margin: 0px 0 5px;
    padding-left: 10px;
  }
}
