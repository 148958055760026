.success-handler-container {
  position: absolute;
  top: 90px;
  min-height: 40px;
  max-width: 561px;
  right: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 186, 7, 0.1);
  border: 1px solid rgba(0, 186, 7, 0.5);
  z-index: 40;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 25px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  animation: slideLeft 1s ease-in-out;
}

.success-handler-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.success-icon-wrrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.success-icon-wrrapper img {
  /* height: 20px; */
  transform: translateY(2px);
}
.cancel-error-buutton-wrrapper {
  margin-left: 10px;
}
.cancel-error-buutton-wrrapper button {
  border: none;
  outline: none;
  background-color: inherit;
  cursor: pointer;
}
.cancel-error-buutton-wrrapper button img {
  height: 18px;
  transform: translateY(2px);
}

@keyframes slideLeft {
  from {
    transform: translate3d(550px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

/* Media Queries */
/* @media (max-width: 615px) {
  .success-handler-container {
    position: absolute;
    color: #2e2e2e;
  }
} */
