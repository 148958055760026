* {
  box-sizing: border-box;
}

/* Font 800 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Font 700 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Font 600 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Font 500 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Font 400 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Font 300 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Font 200 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Font 100 */
@font-face {
  font-family: "BR Firma";
  src: url("./styles/fonts/BRFirma-ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

button {
  font-family: "BR Firma";
}

button:focus {
  outline: none;
}
