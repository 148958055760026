.homepage-container {
  background-color: rgba(241, 241, 241, 0.7);
}

.homepage-container--inner {
  padding-top: 80px;
  font-family: "BR Firma";
  background-color: rgba(241, 241, 241, 0.7);
}

/* Row One Section */
section.home--row-one-section {
  background-color: #f9f9f9;
  overflow: hidden;
}

.home--row-one-section--inner {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  max-width: 1240px;
  margin: auto;
}

.home--row-one-left-container,
.home--row-one-right-container {
  grid-column: span 6 / span 6;
  padding-top: 50px;
}

/* Left Container*/
.home--row-one-left-container {
}

.row-one-major-text-wrapper {
  font-weight: 600;
  font-size: 44px;
  line-height: 55px;
  color: #121212;
  margin-bottom: 20px;
  width: 445px;
  padding-top: 120px;
}

.row-one-sub-text-wrapper {
  width: 502px;
}

.join-waitlist-button-wrapper {
  display: flex;
  align-items: center;
  column-gap: 25px;
  margin-top: 20px;
}

.join-waitlist-button-wrapper a,
.join-waitlist-button-wrapper button {
  width: 250px;
  height: 48px;
  display: flex;
  justify-content: center;
  column-gap: 4px;
  align-items: center;
  background-color: #71bbe0;
  border-radius: 30px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;
  text-decoration: none;
}

.join-waitlist-button-wrapper a img {
  transform: translateY(-1px);
}

.join-waitlist-button-wrapper a:hover,
.join-waitlist-button-wrapper button:hover {
  color: #f9f9f9;
}

.join-waitlist-button-wrapper a:nth-child(2) {
  border: 1.5px solid #71bbe0;
  background-color: transparent;
}

.join-waitlist-button-wrapper a:nth-child(2):hover {
  background-color: #71bbe0;
  color: #121212;
}

/* Right Container */
.home--row-one-right-container {
}

/* Row Image and image wrapper*/
.row-one-image-wrapper {
  position: relative;
}

.row-one--text-rect-wrapper {
  position: absolute;
  width: 191.96px;
  height: 67.28px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background-color: rgba(69, 69, 69, 0.5);
  border-radius: 20px;
  z-index: 30;

  /* txt styles */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #f1f1f1;
}

.row-one--text-rect-wrapper img {
  width: 20px;
  height: 17.44px;
  margin-right: 8px;
}

.text-rect-one {
  right: -20px;
  top: 223.26px;
}

.text-rect-two {
  left: 20px;
  top: 423.26px;
  z-index: 50;
}

.launching-time-rect-wrapper {
  position: absolute;
  width: 503px;
  height: 230px;
  left: 78px;
  top: -26px;
  background-color: #121212;
  border-radius: 70px;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.launching-time-rect-wrapper .minor-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #f9f9f9;
  margin-bottom: 5px;
}

.launch-countown-text {
  font-weight: 600;
  font-size: 32px;
  line-height: 55px;
  display: flex;
  align-items: center;
  color: #f1f1f1;
  margin-bottom: 18px;
  text-align: center;
}

.img--hero-img-1 {
  height: 471px;
  width: 303px;
  position: relative;
  top: 5px;
  transform: translateX(140px);
  margin-top: 105px;
  z-index: 40;
}

.img--bubble-ellipse {
  position: absolute;
  top: 0;
  left: 75px;
  width: 478.06px;
  height: 402.68px;
}

/* Row Two Section */
section.home--row-two-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  max-width: 1240px;
  margin: auto;
  padding: 50px 0;
  overflow: hidden;
}

.home--row-two-left-container {
  grid-column: span 5 / span 5;
}

.home--row-two-right-container {
  grid-column: span 7 / span 7;
  padding-left: 50px;
  padding-top: 70px;
}

/* Row Image and image wrapper*/
.img--hero-img {
  width: 489.76px;
  height: 422.76px;
}

/* All Row Major Text wrapper */
.row-major-text-wrapper {
  font-weight: 600;
  font-size: 34px;
  line-height: 43px;
  color: #121212;
  margin-bottom: 40px;
}

/* All Row Sub Text Wrapper */
.row-sub-text-wrapper {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: #121212;
  max-width: 608px;
}

/* Row Three Section */
section.home--row-three-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  max-width: 1240px;
  margin: auto;
  padding: 50px 0;
  overflow: hidden;
}

.home--row-three-left-container {
  grid-column: span 7 / span 7;
  padding: 70px 0;
  padding-right: 50px;
}
.home--row-three-right-container {
  grid-column: span 5 / span 5;
}

/* Row Four Sction */
section.home--row-four-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  max-width: 1240px;
  margin: auto;
  padding: 50px 0;
  overflow: hidden;
}

.home--row-four-left-container {
  grid-column: span 5 / span 5;
}

.home--row-four-right-container {
  grid-column: span 7 / span 7;
  padding: 70px 0;
  padding-left: 50px;
}

/* Row Five Section */
section.home--row-five-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  max-width: 1240px;
  margin: auto;
  padding: 50px 0;
  overflow: hidden;
}

.home--row-five-left-container {
  grid-column: span 7 / span 7;
  padding: 70px 0;
  padding-right: 50px;
}
.home--row-five-right-container {
  grid-column: span 5 / span 5;
}

/* Row Six Section */
section.home--row-six-section {
  grid-column: span 12 / span 12;
  max-width: 1240px;
  margin: auto;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.row-six-major-text-wrapper {
  margin-bottom: 30px;
}

.row-six-major-text-wrapper,
.row-six-sub-text-wrapper {
  text-align: center;
}

/* waitlist-and-partner-button-wrapper */
.waitlist-and-partner-button-wrapper {
  display: flex;
  justify-content: center;
  margin: 35px 0 0;
}

.lower--join-waitlist-button-wrapper {
  margin: 0;
}

.waitlist-form-wrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.waitlist-form-wrapper form {
  display: flex;
}

.waitlist-email-form-group input {
  width: 350px;
  height: 50px;
  font-size: 15px;
  border: 1px solid #71bbe0;
  border-radius: 20px;
  padding: 0 20px;
  background-color: #fefefe;
  padding-right: 40px;
}

.waitlist-email-form-group input:focus {
  outline: 1px solid #71bbe0;
}

.submit-email-btn-wrapper {
  transform: translateX(-30px);
}

.submit-email-btn-wrapper button {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #71bbe0;
  border-radius: 20px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #121212;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;
  color: #f9f9f9;
}

.email-success-container {
  height: 50px;
  padding: 0 35px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #71bbe0;
  border-radius: 20px;
  background-color: inherit;
}

/* Row Seven Section */
section.home--row-seven-section {
  width: 100%;
  height: 550px;
  margin: auto;
  background-image: url(../../images/hero-img-6.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.home--row-seven-section--inner {
  background-color: rgba(18, 18, 18, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-seven-major-text-wrapper {
  color: #f1f1f1;
}

.partners-container {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;

  /* Text styles */
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: #f9f9f9;
}
.partners-container--MOBILE {
  display: none;
  justify-content: space-around;
  margin: 0px 0 40px;

  /* Text styles */
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #f9f9f9;
}

.partners-container--MOBILE .partner-wrapper {
  margin: 0 30px;
}

.partners-container .partner-wrapper {
  width: 101px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 50px;
}

.partners-container .partner-wrapper img {
  margin-bottom: 10px;
}

.home--partner-with-us-link-wrapper a {
  width: 222px;
  height: 48px;
  text-decoration: none;
  height: 40px;
  background-color: #f9f9f9;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all;
  transition-duration: 300ms;
  border-radius: 20px;
}

.home--partner-with-us-link-wrapper a:hover {
  background-color: #71bbe0;
  color: #f9f9f9;
}

.app-form-info {
  margin-top: 10px;
  font-size: 15px;
  color: rgb(188, 0, 0);
}

.none {
  display: none !important;
}

/* Media queries */
@media (max-width: 1290px) {
  /* Row One Section */
  section.home--row-one-section {
    padding-left: 20px;
    padding-right: 40px;
  }

  /* Row Two Section */
  section.home--row-two-section,
  section.home--row-three-section,
  section.home--row-four-section,
  section.home--row-five-section {
    padding: 50px 20px;
  }
  section.home--row-six-section {
    padding: 70px 20px;
  }
}

@media (max-width: 1210px) {
  /* Row One */
  .img--hero-img-1 {
    height: 421px;
    width: 273px;
    position: relative;
    top: 5px;
    transform: translateX(90px);
    margin-top: 105px;
    z-index: 40;
  }

  .img--bubble-ellipse {
    position: absolute;
    top: 0;
    left: 35px;
    width: 428.06px;
    height: 352.68px;
  }

  .launching-time-rect-wrapper {
    position: absolute;
    width: 403px;
    height: 200px;
    left: 48px;
    top: -26px;
    border-radius: 50px;
  }

  .launching-time-rect-wrapper .minor-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 5px;
  }

  .launch-countown-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 18px;
  }

  .row-one--text-rect-wrapper {
    position: absolute;
    width: 145.96px;
    height: 47.28px;
    padding: 0 8px;
    border-radius: 10px;

    /* txt styles */
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .row-one--text-rect-wrapper img {
    width: 20px;
    height: 15.44px;
    margin-right: 8px;
  }

  .text-rect-one {
    right: -30px;
    top: 223.26px;
  }

  .text-rect-two {
    left: 20px;
    top: 423.26px;
    z-index: 50;
  }

  .row-one-major-text-wrapper {
    width: 445px;
  }

  .row-one-sub-text-wrapper {
    width: 440px;
  }
  /* Row Two downwards */
  .img--hero-img {
    width: 409.76px;
    height: 342.76px;
  }

  /* Row Two */
  .home--row-two-left-container {
    grid-column: span 6 / span 6;
  }

  .home--row-two-right-container {
    grid-column: span 6 / span 6;
    padding-top: 20px;
    padding-left: 0px;
  }

  /* Row Three */
  .home--row-three-left-container {
    grid-column: span 6 / span 6;
    padding-top: 20px;
  }

  .home--row-three-right-container {
    grid-column: span 6 / span 6;
  }

  /* Row Four */
  .home--row-four-left-container {
    grid-column: span 6 / span 6;
  }

  .home--row-four-right-container {
    grid-column: span 6 / span 6;
    padding-top: 20px;
    padding-left: 0px;
  }

  /* Row Five */
  .home--row-five-left-container {
    grid-column: span 6 / span 6;
    padding-top: 20px;
  }
  .home--row-five-right-container {
    grid-column: span 6 / span 6;
  }

  /* Row Seven */
  .partners-container .partner-wrapper {
    margin: 0 30px;
  }
}

@media (max-width: 890px) {
  /* Row One */

  .home--row-one-section--inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home--row-one-left-container,
  .home--row-one-right-container {
    grid-column: span 12 / span 12;
    padding-top: 50px;
  }

  /* Row Image and image wrapper*/
  .row-one-image-wrapper {
    position: relative;
    left: -20px;
  }

  .img--hero-img-1 {
    transform: translateX(0px);
  }

  .img--bubble-ellipse {
    left: -50px;
  }

  .launching-time-rect-wrapper {
    position: absolute;
    width: 403px;
    height: 170px;
    left: -40px;
    top: -6px;
    border-radius: 50px;
  }

  .row-one--text-rect-wrapper {
    position: absolute;
    width: 145.96px;
    height: 47.28px;
    padding: 0 8px;
    border-radius: 10px;

    /* txt styles */
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .row-one--text-rect-wrapper img {
    width: 20px;
    height: 15.44px;
    margin-right: 8px;
  }

  .text-rect-one {
    right: -150px;
    top: 223.26px;
  }

  .text-rect-two {
    left: -80px;
    top: 423.26px;
    z-index: 50;
  }

  .row-one-major-text-wrapper {
    padding-top: 0px;
  }

  /* Row Two */
  .home--row-two-left-container {
    grid-column: span 12 / span 12;
    display: flex;
    justify-content: center;
  }

  .home--row-two-right-container {
    grid-column: span 12 / span 12;
    padding-top: 50px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Row Three */
  .home--row-three-left-container {
    grid-column: span 12 / span 12;
    padding-top: 50px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home--row-three-right-container {
    grid-column: span 12 / span 12;
    grid-column-start: 1;
    grid-row-start: 1;
    display: flex;
    justify-content: center;
  }

  /* Row Four */
  .home--row-four-left-container {
    grid-column: span 12 / span 12;
    display: flex;
    justify-content: center;
  }

  .home--row-four-right-container {
    grid-column: span 12 / span 12;
    padding-top: 50px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Row Five */
  .home--row-five-left-container {
    grid-column: span 12 / span 12;
    padding-top: 50px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home--row-five-right-container {
    grid-column: span 12 / span 12;
    grid-column-start: 1;
    grid-row-start: 1;
    display: flex;
    justify-content: center;
  }

  /* Row Seven */

  .partners-container {
    /* Text styles */
    font-size: 14px;
  }

  .partners-container .partner-wrapper {
    margin: 0 0;
    margin-left: 30px;
  }

  .partners-container .partner-wrapper:nth-child(1) {
    margin-left: 0;
  }

  .partner-wrapper img {
    height: 36px;
  }
}

@media (max-width: 670px) {
  .partners-container {
    /* Text styles */
    font-size: 13px;
    line-height: 20px;
  }

  .partners-container .partner-wrapper {
    width: 80px;
  }

  .partners--ngo,
  .partners--hmo {
    display: none !important;
  }

  .partners-container--MOBILE {
    display: flex;
  }

  /* .partners--ngo--MOBILE,
  .partners--hmo--MOBILE {
    display: block;
  } */

  .join-waitlist-button-wrapper {
    display: flex;
    flex-direction: column;
    column-gap: 0px;
    row-gap: 20px;
    margin-top: 20px;
  }

  /* Subscribe form */
  .waitlist-form-container {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }

  .submit-email-btn-wrapper {
    transform: translateX(0);
  }

  .submit-email-btn-wrapper button {
    width: 220px;
    height: 45px;
  }
}

@media (max-width: 530px) {
  /* Row One */
  .row-one-major-text-wrapper {
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 15px;
    width: 100%;
  }

  .row-one-sub-text-wrapper {
    width: 100%;
  }
  /* All Row Major Text wrapper */
  .row-major-text-wrapper {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 25px;
  }

  /* All Row Sub Text Wrapper */
  .row-sub-text-wrapper {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .row-one-image-wrapper {
    position: relative;
    left: 0px;
  }

  .img--hero-img-1 {
    width: 243px;
    height: 361px;
    transform: translateX(0px);
    margin-top: 105px;
    top: 5px;
    z-index: 40;
  }

  .img--bubble-ellipse {
    position: absolute;
    top: 0;
    width: 325.06px;
    height: 252.68px;
    left: -30px;
  }

  .launching-time-rect-wrapper {
    position: absolute;
    width: 280px;
    height: 120px;
    left: -6px;
    top: -6px;
    border-radius: 20px;
  }

  .row-one--text-rect-wrapper {
    position: absolute;
    width: 133.96px;
    height: 40.28px;
    padding: 0 6px;
    border-radius: 10px;

    /* txt styles */
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .row-one--text-rect-wrapper img {
    width: 16px;
    height: 15.44px;
    margin-right: 6px;
  }

  .text-rect-one {
    right: -70px;
    top: 185.26px;
  }

  .text-rect-two {
    left: -45px;
    top: 403.26px;
    z-index: 50;
  }

  .launching-time-rect-wrapper .minor-text {
    font-size: 13px;
    line-height: 22px;
  }

  .launch-countown-text {
    font-size: 18px;
    line-height: 35px;
  }

  .join-waitlist-button-wrapper button,
  .join-waitlist-button-wrapper a,
  .home--partner-with-us-link-wrapper a {
    width: 225px;
    height: 40px;
    border-radius: 20px;
    font-size: 15px;
  }

  .app-form-info {
    font-size: 14px;
  }
}

@media (max-width: 445px) {
  /* Row One */
  /* Row Two downwards */
  .img--hero-img {
    width: 310.76px;
    height: 252.76px;
  }

  .email-success-container {
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
  }

  .waitlist-email-form-group {
    margin-top: 20px;
  }

  .waitlist-email-form-group input {
    max-width: 320px;
    height: 40px;
    font-size: 13px;
    border-radius: 20px;
    padding: 0 18px;
    padding-right: 35px;
  }

  .waitlist-email-form-group input::placeholder {
    font-size: 13px;
  }

  .submit-email-btn-wrapper button {
    height: 40px;
    font-size: 13px;
    line-height: 15px;
  }

  .app-form-info {
    font-size: 13px;
  }
}

@media (max-width: 380px) {
  .row-one--text-rect-wrapper {
    width: 120.96px;
    height: 40.28px;
    padding: 0 5px;

    /* txt styles */
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
  }

  .row-one--text-rect-wrapper img {
    width: 15px;
    margin-right: 4px;
  }

  .text-rect-one {
    right: -52px;
    top: 185.26px;
  }

  .text-rect-two {
    left: -30px;
    top: 403.26px;
    z-index: 50;
  }

  .img--hero-img-1 {
    transform: translateX(-5px);
  }
}

@media (max-width: 350px) {
  .email-success-container {
    height: 40px;
    padding: 0 10px;
    font-size: 13px;
  }
}
