.footer-container {
  background-color: #f1f1f1;
  font-family: "BR Firma";
  padding-top: 50px;
}

.footer-container--inner {
  max-width: 1240px;
  height: 159px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Left Section */
.footer-company-logo-wrapper img {
  height: 50px;
}

.copyright-text {
  font-weight: 500;
  line-height: 20px;
  color: #121212;
  margin-top: 5px;
}

/* Right Section */
.footer-right-section-wrapper {
  display: flex;
}

/* social-media-links-container */
.social-media-links-title,
.privacy-policy-link-title,
.terms-and-conditions-link-title {
  font-weight: 500;
  line-height: 20px;
  color: #121212;
}

.social-media-links-section {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  margin-top: 10px;
}

.privacy-policy-link-container {
  margin: 0 70px;
}

.privacy-policy-link,
.terms-and-conditions-link {
  margin-top: 15px;
  text-align: center;
}

.privacy-policy-link a,
.terms-and-conditions-link a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ca74ae;
}

/* Media queries */
@media (max-width: 1290px) {
  .footer-container {
    padding: 50px 20px 0 20px;
  }
}

@media (max-width: 870px) {
  .privacy-policy-link-container {
    margin: 0 30px;
  }
}

@media (max-width: 790px) {
  .footer-container--inner {
    height: max-content;
    padding-bottom: 30px;
  }
  /* Right Section */
  .footer-right-section-wrapper {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    row-gap: 25px;
  }

  .social-media-links-container,
  .privacy-policy-link-container,
  .terms-and-conditions-link-container {
    grid-column: span 6 / span 6;
  }

  .privacy-policy-link,
  .terms-and-conditions-link {
    margin-top: 10px;
    text-align: left;
  }
}

@media (max-width: 640px) {
  .social-media-links-container,
  .privacy-policy-link-container,
  .terms-and-conditions-link-container {
    grid-column: span 8 / span 8;
  }

  .privacy-policy-link-container {
    margin: 0;
  }
}

@media (max-width: 530px) {
  .footer-company-logo-wrapper img {
    height: 40px;
  }
  .copyright-text {
    font-size: 12px;
    line-height: 15px;
  }

  /* social-media-links-container */
  .social-media-links-title,
  .privacy-policy-link-title,
  .terms-and-conditions-link-title {
    font-weight: 500;
    line-height: 14px;
    color: #121212;
    font-size: 14px;
  }

  .privacy-policy-link a,
  .terms-and-conditions-link a {
    font-size: 12px;
    line-height: 10px;
  }

  .social-media-links-container,
  .privacy-policy-link-container,
  .terms-and-conditions-link-container {
    grid-column: span 12 / span 12;
  }
}

@media (max-width: 345px) {
  /* social-media-links-container */
  .social-media-links-title,
  .privacy-policy-link-title,
  .terms-and-conditions-link-title {
    font-size: 13px;
  }
}
