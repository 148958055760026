.questionnaire-container {
  background-color: rgba(241, 241, 241, 0.7);
}

.questionnaire-container--inner {
  max-width: 1240px;
  margin: auto;
  padding: 50px 0;
  min-height: 100vh;
  font-family: "BR Firma";
  padding-top: 80px;
}

.questionnaire-form-title {
  font-size: 26px;
  font-weight: 500;
}

/* be-a-partner-form-wrapper */
.questionnaire-form-wrapper {
  margin-top: 50px;
}

.questionnaire-form-wrapper form {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  row-gap: 30px;
  column-gap: 30px;
  max-width: 900px;
  margin-top: 30px;
}

.questionnaire-form-group,
.marital-status-form-group {
  grid-column: span 10 / span 10;
}

.questionnaire-form-group textarea {
  min-height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.other-questions-4-form-group {
}

.css-319lph-ValueContainer,
.css-g1d714-ValueContainer {
  padding: 0 8px !important;
  width: 88% !important;
  overflow: hidden;
  flex-wrap: nowrap !important;
  -webkit-box-flex-wrap: nowrap !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
}

.css-g1d714-ValueContainer {
  position: absolute !important;
}

.css-1s2u09g-control {
  padding-right: 10px;
  padding-left: 10px;
  height: 40px;
  border-radius: 20px !important;
  background-color: transparent !important;
  border: 1px solid #71bbe0 !important;
  font-size: 13px;
  overflow: hidden;
  flex-wrap: nowrap !important;
}

.other-questions-4-form-group .css-1pahdxg-control {
  height: 40px;
  border-radius: 20px;
  background-color: inherit !important;
  border: 1px solid #71bbe0;
  font-size: 13px;
  min-height: 100% !important;
  padding: 0 10px !important;
  flex-wrap: nowrap !important;
}

.css-6j8wv5-Input {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: black !important;
}

.css-6j8wv5-Input input,
.css-14el2xx-placeholder {
  color: black !important;
  font-size: 13px;
}

.css-tj5bde-Svg {
  fill: black !important;
}

.css-1hb7zxy-IndicatorsContainer {
  position: absolute;
  right: 10px;
}

.css-tlfecz-indicatorContainer {
  color: black !important;
}

.css-1d8n9bt {
  padding: 0 8px !important;
}

.css-1hwfws3 {
  flex-wrap: nowrap !important;
}

/* Media queries */
@media (max-width: 1290px) {
  /* questionnaire-container*/
  .questionnaire-container--inner {
    padding: 50px 20px;
  }
}

@media (max-width: 670px) {
  .css-319lph-ValueContainer,
  .css-g1d714-ValueContainer {
    padding: 0 8px !important;
    width: 75% !important;
  }
}
@media (max-width: 530px) {
  .questionnaire-form-title {
    font-size: 19px;
    font-weight: 500;
  }

  .questionnaire-form-wrapper form {
    column-gap: 0px;
  }

  .questionnaire-form-group {
    grid-column: span 12 / span 12;
  }
}
